var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"text-center",class:("bg-" + _vm.color)},[_c('div',{staticClass:"truncate"},[_c('h3',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(_vm.statistic))]),_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(_vm.statisticTitle))]),(_vm.statisticTitle)?_c('div',[(
          _vm.statisticTotal != '' &&
            _vm.statisticTotal >= 0 &&
            !_vm.statisticTitle.includes('HOL')
        )?_c('span',{staticClass:"text-success",staticStyle:{"font-size":"12px"}},[_c('money-format',{attrs:{"value":parseFloat(_vm.statisticTotal.toString().replaceAll(/,/g, '')),"subunits-value":false,"currencyCode":_vm.underlying,"hide-subunits":false}})],1):_vm._e(),(
          _vm.statisticTotal != '' &&
            _vm.statisticTotal < 0 &&
            !_vm.statisticTitle.includes('HOL')
        )?_c('span',{staticClass:"text-danger",staticStyle:{"font-size":"12px"}},[_c('money-format',{attrs:{"value":parseFloat(_vm.statisticTotal.toString().replaceAll(/,/g, '')),"subunits-value":false,"currencyCode":_vm.underlying,"hide-subunits":false}})],1):_vm._e()]):_vm._e(),_c('div',{staticClass:"mt-1"},[(_vm.startAmount != '' && !_vm.totalAmountShowEnd)?_c('span',{staticClass:"font-weight-bold float-left",staticStyle:{"font-size":"12px"}},[_vm._v("Total:")]):_vm._e(),(_vm.statisticTitle.includes('HOL'))?_c('div',[(_vm.startAmount != '' && !_vm.totalAmountShowEnd)?_c('p',{staticClass:"text-right",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(parseFloat( _vm.totalAmount.toString().replaceAll(/,/g, "") ).toLocaleString("tr-TR", { minimumFractionDigits: 2 }))+" ")]):_vm._e()]):_c('div',[(_vm.startAmount != '' && !_vm.totalAmountShowEnd)?_c('money-format',{staticClass:"text-right",staticStyle:{"font-size":"12px"},attrs:{"value":parseFloat(_vm.totalAmount.toString().replaceAll(/,/g, '')),"subunits-value":false,"currencyCode":_vm.underlying,"hide-subunits":false}}):_vm._e()],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }