<template>
  <b-card class="text-center " style="cursor: pointer;" v-if="link=='admin-panel'" @click="toggleList">
    <b-avatar class="mb-1" :variant="`light-${color}`" size="45">
      <feather-icon size="21" :icon="icon" />
    </b-avatar>
    <div class="truncate">
      <h4 class="mb-15 font-weight-bolder">
        {{ statistic }}
      </h4>
      <!--  <span>{{ statisticTitle }}</span> !-->
    </div>
  </b-card>

  <b-card v-else class="text-center " style="cursor: pointer;" @click="goToPage()">
    <b-avatar class="mb-1" :variant="`light-${color}`" size="45">
      <feather-icon size="21" :icon="icon" />
    </b-avatar>
    <div class="truncate">
      <h4 class="mb-15 font-weight-bolder">
        {{ statistic }}
      </h4>
      <!--  <span>{{ statisticTitle }}</span> !-->
    </div>
  </b-card>
</template>

<script>
import { BCard, BAvatar } from 'bootstrap-vue';
import router from '@/router';

export default {
  components: {
    BCard,
    BAvatar,
  },

  methods: {
    goToPage() {
      router.push({ name: 'settings-' + this.link });
    },

    toggleList(){
      this.$emit("update:activateList", !this.activateList);
    },

  },

  props: {
    icon: {
      type: String,
      required: true,
    },
    statistic: {
      type: [Number, String],
      required: true,
    },
    statisticTitle: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
    link: {
      type: String,
      default: '',
    },
    activateList: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
