<template>
  <b-card class="text-center" :class="`bg-${color}`">
    <div class="truncate">
      <h3 class="font-weight-bolder">{{ statistic }}</h3>
      <span style="font-size:12px">{{ statisticTitle }}</span>

      <!-- static total -->
      <div v-if="statisticTitle">
        <span
          style="font-size:12px"
          class="text-success"
          v-if="
            statisticTotal != '' &&
              statisticTotal >= 0 &&
              !statisticTitle.includes('HOL')
          "
        >
          <money-format
            :value="parseFloat(statisticTotal.toString().replaceAll(/,/g, ''))"
            :subunits-value="false"
            :currencyCode="underlying"
            :hide-subunits="false"
          >
          </money-format
        ></span>

        <span
          style="font-size:12px"
          class="text-danger"
          v-if="
            statisticTotal != '' &&
              statisticTotal < 0 &&
              !statisticTitle.includes('HOL')
          "
        >
          <money-format
            :value="parseFloat(statisticTotal.toString().replaceAll(/,/g, ''))"
            :subunits-value="false"
            :currencyCode="underlying"
            :hide-subunits="false"
          >
          </money-format>
        </span>
      </div>

      <!-- start amount -->
      <!-- <div v-if="statisticTitle && !statisticTitle.includes('HOL')">
        <span
          style="font-size:12px"
          v-if="startAmount != '' && totalAmountShowStart"
          class="font-weight-bold float-left"
          >Start:</span
        >
        <money-format
          style="font-size:12px"
          class="text-right text-success"
          v-if="startAmount != '' && totalAmountShowStart && startAmount >= 0"
          :value="parseFloat(startAmount.toString().replaceAll(/,/g, ''))"
          :subunits-value="false"
          :currencyCode="underlying"
          :hide-subunits="false"
        >
        </money-format>
        <money-format
          style="font-size:12px"
          class="text-right text-danger"
          v-if="startAmount != '' && totalAmountShowStart && startAmount < 0"
          :value="parseFloat(startAmount.toString().replaceAll(/,/g, ''))"
          :subunits-value="false"
          :currencyCode="underlying"
          :hide-subunits="false"
        >
        </money-format>
      </div> -->
      <!-- end amount -->
      <!-- <div v-if="statisticTitle && !statisticTitle.includes('HOL')">
        <span
          style="font-size:12px"
          v-if="startAmount != '' && totalAmountShowEnd"
          class="font-weight-bold float-left"
          >End:</span
        >

        <money-format
          style="font-size:12px"
          class="text-right text-success"
          v-if="startAmount != '' && totalAmountShowEnd && endAmount >= 0"
          :value="parseFloat(endAmount.toString().replaceAll(/,/g, ''))"
          :subunits-value="false"
          :currencyCode="underlying"
          :hide-subunits="false"
        >
        </money-format>

        <money-format
          style="font-size:12px"
          class="text-right text-danger"
          v-if="startAmount != '' && totalAmountShowEnd && endAmount < 0"
          :value="parseFloat(endAmount.toString().replaceAll(/,/g, ''))"
          :subunits-value="false"
          :currencyCode="underlying"
          :hide-subunits="false"
        >
        </money-format>
      </div> -->
      <!-- // TOTAL -->
      <div class="mt-1">
        <span
          style="font-size:12px"
          v-if="startAmount != '' && !totalAmountShowEnd"
          class="font-weight-bold float-left"
          >Total:</span
        >
        <div v-if="statisticTitle.includes('HOL')">
          <p
            style="font-size:12px"
            class="text-right"
            v-if="startAmount != '' && !totalAmountShowEnd"
          >
            {{
              parseFloat(
                totalAmount.toString().replaceAll(/,/g, "")
              ).toLocaleString("tr-TR", { minimumFractionDigits: 2 })
            }}
          </p>
        </div>
        <div v-else>
          <money-format
            style="font-size:12px"
            class="text-right"
            v-if="startAmount != '' && !totalAmountShowEnd"
            :value="parseFloat(totalAmount.toString().replaceAll(/,/g, ''))"
            :subunits-value="false"
            :currencyCode="underlying"
            :hide-subunits="false"
          ></money-format>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import { BCard, BAvatar } from "bootstrap-vue";
import MoneyFormat from "vue-money-format";
export default {
  components: {
    BCard,
    BAvatar,
    MoneyFormat,
  },
  props: {
    statistic: {
      type: [Number, String],
      required: true,
    },
    statisticTitle: {
      type: String,
      default: "",
    },
    statisticTotal: {
      type: String,
      default: "",
    },
    underlying: {
      type: String,
      default: "",
    },
    startAmount: {
      type: String,
      default: "",
    },
    endAmount: {
      type: String,
      default: "",
    },
    totalAmount: {
      type: String,
      default: "",
    },
    totalAmountShowStart: {
      type: Boolean,
      default: true,
    },
    totalAmountShowEnd: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: "white",
    },
  },
};
</script>
